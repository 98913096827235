import Container from "../Container/Container"
import { adminCropped1x, adminCropped2x } from "../Images"
import Heading from "../Heading/Heading"
import { ButtonLink } from "@website/components/Button/Button"
import { routes } from "../../routes"
import classNames from "classnames"

const ctaClasses = classNames(
  `relative mt-10 grid items-center gap-x-16 overflow-hidden rounded-xl bg-brand-primary px-4 text-brand-text-primary shadow-default dark:bg-brand-dark-primary dark:text-brand-dark-text-primary sm:mt-14 md:px-8 lg:mt-16 lg:grid-cols-2-3 lg:px-0`
)
const imageClasses = classNames(`relative z-10 -mr-px rounded-t-lg lg:ml-auto lg:rounded-tl-xl lg:rounded-tr-none`)
const imageWrapperClasses = classNames(`-mx-4 self-end sm:mx-0 lg:mt-8`)
const textClasses = classNames(`grid max-w-32 gap-y-6 py-8 md:gap-y-8 md:py-16 lg:p-8 lg:py-12`)

export default function CTA() {
  return (
    <Container>
      <div className={ctaClasses}>
        <div className={textClasses}>
          <Heading type="h2" size="h1" currentColor>
            Chystáte závod? <br />
            Pomůžeme vám
          </Heading>
          <p>
            Služba <strong>naZávody.cz</strong> je registrační systém přizpůsobený pro závody a sportovní akce. Správa startovních listin, automatické kategorie, statistiky, párování plateb a mnoho
            dalšího. Zjednodušení práce organizátorům a snadné přihlašování pro závodníky.
          </p>
          <div>
            <ButtonLink to={routes.forOrganizers.getLink()} style="inverted">
              Více informací
            </ButtonLink>
          </div>
        </div>
        <div className={imageWrapperClasses}>
          <img className={imageClasses} loading="lazy" src={adminCropped1x} srcSet={`${adminCropped1x} 1x, ${adminCropped2x} 2x`} width={690} height={480} alt="Administrační prostředí" />
        </div>
      </div>
    </Container>
  )
}
